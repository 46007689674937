import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input } from "../components";
import { db, auth, storage } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { ObjetoVazio } from "../components/Formatar";

const Financeiro = () => {
  const [dados, setDados] = useState({});
  const [alterar, setAlterar] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();
  const hoje = moment().format();

  const setData = async () => {
    await setDoc(doc(db, "cangucu2025", uid), alterar, { merge: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!ObjetoVazio(alterar)) {
      setLoading(true);
      setData().then(() => {
        alert("Enviado com sucesso.");
        window.location.reload(false);
      });
    }
  };

  const uploadFile = async (file, docPath, docUrl) => {
    if (!file) return;

    setLoading(true);

    const path = ref(storage, `cangucu2025/${uid}/${docPath}`);

    const upload = uploadBytesResumable(path, file, file.type);

    upload.on(
      "state_changed",
      () => {},
      () => {},
      () => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setAlterar({ ...alterar, [docUrl]: url, [docPath]: "Enviado" });
          setDados({ ...dados, [docUrl]: url });
          setLoading(false);
        });
      }
    );
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "cangucu2025", uid));

    const data = qry.data();

    if (data?.status === "a") {
      setDados(data);
      setLoading(false);
    } else {
      navigate("../home");
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      {
        <Frame
          title="Financeiro"
          body={
            <div>
              <table className="table table-sm table-striped table-bordered table-hover my-4">
                <thead>
                  <tr>
                    <th>Mês</th>
                    <th>Valor</th>
                    <th>Recibo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Fevereiro 2025</td>
                    <td>
                      {dados?.pagto0225 ||
                        (dados?.tipo === "2"
                          ? "R$ 425,00"
                          : dados?.dataInicio < "2025-02-10"
                          ? "R$ 572,00"
                          : "R$ 429,00")}
                    </td>
                    <td>
                      {dados?.pagto0225 === "Pago" ? (
                        <Link to="/recibo" state={{ mes: "0225" }}>
                          Abrir
                        </Link>
                      ) : (
                        dados?.pagto0225
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Março 2025</td>
                    <td>
                      {dados?.pagto0325 ||
                        (dados?.tipo === "2"
                          ? "R$ 425,00"
                          : hoje > "2025-03-08"
                          ? "R$ 630,00"
                          : "R$ 572,00")}
                    </td>
                    <td>
                      {dados?.pagto0325 === "Pago" ? (
                        <Link to="/recibo" state={{ mes: "0325" }}>
                          Abrir
                        </Link>
                      ) : (
                        dados?.pagto0325
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Abril 2025</td>
                    <td>
                      {dados?.tipo === "2"
                        ? "R$ 425,00"
                        : hoje > "2025-04-08"
                        ? "R$ 630,00"
                        : "R$ 572,00"}
                    </td>
                    <td>
                      {dados?.pagto0425 === "Pago" ? (
                        <Link to="/recibo" state={{ mes: "0425" }}>
                          Abrir
                        </Link>
                      ) : (
                        dados?.pagto0425
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Maio 2025</td>
                    <td>
                      {dados?.tipo === "2"
                        ? "R$ 425,00"
                        : hoje > "2025-05-08"
                        ? "R$ 630,00"
                        : "R$ 572,00"}
                    </td>
                    <td>
                      {dados?.pagto0525 === "Pago" ? (
                        <Link to="/recibo" state={{ mes: "0525" }}>
                          Abrir
                        </Link>
                      ) : (
                        dados?.pagto0525
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Junho 2025</td>
                    <td>
                      {dados?.tipo === "2"
                        ? "R$ 425,00"
                        : hoje > "2025-06-07"
                        ? "R$ 630,00"
                        : "R$ 572,00"}
                    </td>
                    <td>
                      {dados?.pagto0625 === "Pago" ? (
                        <Link to="/recibo" state={{ mes: "0625" }}>
                          Abrir
                        </Link>
                      ) : (
                        dados?.pagto0625
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Julho 2025</td>
                    <td>
                      {dados?.tipo === "2"
                        ? "R$ 425,00"
                        : hoje > "2025-07-08"
                        ? "R$ 630,00"
                        : "R$ 572,00"}
                    </td>
                    <td>
                      {dados?.pagto0725 === "Pago" ? (
                        <Link to="/recibo" state={{ mes: "0725" }}>
                          Abrir
                        </Link>
                      ) : (
                        dados?.pagto0725
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Agosto 2025</td>
                    <td>
                      {dados?.tipo === "2"
                        ? "R$ 420,00"
                        : hoje > "2025-08-08"
                        ? "R$ 585,00"
                        : "R$ 545,00"}
                    </td>
                    <td>
                      {dados?.pagto0824 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0824" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Setembro 2025</td>
                    <td>
                      {dados?.tipo === "2"
                        ? "R$ 420,00"
                        : hoje > "2025-09-07"
                        ? "R$ 585,00"
                        : "R$ 545,00"}
                    </td>
                    <td>
                      {dados?.pagto0924 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0924" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Outubro 2025</td>
                    <td>
                      {dados?.tipo === "2"
                        ? "R$ 420,00"
                        : hoje > "2025-10-08"
                        ? "R$ 585,00"
                        : "R$ 545,00"}
                    </td>
                    <td>
                      {dados?.pagto1024 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "1024" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Novembro 2025</td>
                    <td>
                      {dados?.tipo === "2"
                        ? "R$ 420,00"
                        : hoje > "2025-11-08"
                        ? "R$ 585,00"
                        : "R$ 545,00"}
                    </td>
                    <td>
                      {dados?.pagto1124 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "1124" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Dezembro 2025</td>
                    <td>
                      {dados?.tipo === "2"
                        ? "R$ 420,00"
                        : hoje > "2025-12-07"
                        ? "R$ 585,00"
                        : "R$ 545,00"}
                    </td>
                    <td>
                      {dados?.pagto1224 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "1224" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr> */}
                </tbody>
              </table>

              <div className="justify mb-4">
                <p>
                  <i>
                    Para pagar sua mensalidade, faça um pix para a chave abaixo
                    e encaminhe o comprovante no mês correspondente.
                  </i>
                </p>
              </div>

              <div className="mb-4">
                <h5>Chave Pix CNPJ:</h5>
                <h5> 92.189.612/0001-92</h5>
              </div>

              <form className="mb-4 text-start" onSubmit={handleSubmit}>
                {!dados?.pagto0225 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0225"
                        label="Fevereiro 2025"
                        type="file"
                        url={dados?.urlPagto0225}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0225",
                            "urlPagto0225"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {!dados?.pagto0325 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0325"
                        label="Março 2025"
                        type="file"
                        url={dados?.urlPagto0325}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0325",
                            "urlPagto0325"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {!dados?.pagto0425 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0425"
                        label="Abril 2025"
                        type="file"
                        url={dados?.urlPagto0425}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0425",
                            "urlPagto0425"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {!dados?.pagto0525 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0525"
                        label="Maio 2025"
                        type="file"
                        url={dados?.urlPagto0525}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0525",
                            "urlPagto0525"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}
                {!dados?.pagto0625 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0625"
                        label="Junho 2025"
                        type="file"
                        url={dados?.urlPagto0625}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0625",
                            "urlPagto0625"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto0725 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0725"
                        label="Julho 2025"
                        type="file"
                        url={dados?.urlPagto0725}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0725",
                            "urlPagto0725"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {/* {!dados?.pagto0825 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0825"
                        label="Agosto 2025"
                        type="file"
                        url={dados?.urlPagto0825}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0825",
                            "urlPagto0825"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto0925 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0925"
                        label="Setembro 2025"
                        type="file"
                        url={dados?.urlPagto0925}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0925",
                            "urlPagto0925"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto1025 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto1025"
                        label="Outubro 2025"
                        type="file"
                        url={dados?.urlPagto1025}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto1025",
                            "urlPagto1025"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto1125 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto1125"
                        label="Novembro 2025"
                        type="file"
                        url={dados?.urlPagto1125}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto1125",
                            "urlPagto1125"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto1225 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto1225"
                        label="Dezembro 2025"
                        type="file"
                        url={dados?.urlPagto1225}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto1225",
                            "urlPagto1225"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )} */}

                {!ObjetoVazio(alterar) && (
                  <Row className="mt-2">
                    <Col className="d-grid">
                      <button type="submit" className="btn btn-primary">
                        ENVIAR
                      </button>
                    </Col>
                  </Row>
                )}
              </form>
            </div>
          }
        />
      }
    </>
  );
};

export default Financeiro;
